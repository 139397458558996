import { Ballot } from "./index"
import { createElement } from "react"
import { createRoot } from "react-dom/client"
import { BallotSharePopup, CandidateSharePopup } from "./SharePopup"

export interface R3BBReactComponents {
    Ballot: typeof Ballot
    BallotSharePopup: typeof BallotSharePopup
    CandidateSharePopup: typeof CandidateSharePopup
    createElement: typeof createElement
    createRoot: typeof createRoot
}

declare global {
    interface Window {
        r3bb_ReactComponents: any
    }
}

if ((window as any).r3bb_ReactComponents !== undefined) {
    console.error(
        "window.r3bb_ReactComponents is already defined (did you import twice?)"
    )
} else {
    window.r3bb_ReactComponents = {}
}

;(() => {
    // This is meant to be ugly so people won't use it
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const exports = window.r3bb_ReactComponents
    exports.Ballot = Ballot
    exports.BallotSharePopup = BallotSharePopup
    exports.CandidateSharePopup = CandidateSharePopup
    exports.createElement = createElement
    exports.createRoot = createRoot
})()
