.ballotSharePopup {
    margin-top: 10px;
    --light: #f9f9fb;
}

.ballotSharePopup p {
    font-weight: unset;
    font-family: "Doppio One";
}

.snapshotBottomButton {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    width: 50px !important;
    /* height: 50px !important; */
    margin-left: 6px !important;
    background-color: var(--light);
    padding-top: 9px;
    padding-bottom: 9px;
    border-radius: 8px;
}

.shareButton {
    background-color: #F9F9FB !important;
}

.ballotSharePopup button {
    background-color: #F9F9FB !important;
}

/* .snapshotBottomButton > img {
    width: 30px;
    height: 30px;
} */