/* eslint-disable @typescript-eslint/naming-convention */
import * as tempStyles from "./Ballot.module.css"
// import Image from "next/image"
import { Helmet } from "react-helmet"
import React, { useState, useEffect, useRef } from "react"
import api from "../lib/helpers/api"
import BallotCard from "../react_components/BallotCard"
import ColorBlendIterator from "../lib/ColorBlendIterator"
import polylabel from "polylabel"
import geoArea from "@turf/area"

// Workaround for https://github.com/mrmckeb/typescript-plugin-css-modules/issues/16#issuecomment-1777990377
type Styles = Readonly<Record<string, string>>
const styles = tempStyles as unknown as Styles

type Color = [number, number, number]

interface Candidate {
    candidate_id: number
    first_name: string
    middle_name: string | null
    last_name: string
    age?: any
    election_id: string
    election_date: string
    election_type_id: string
    election_type: string
    government_level_id: number
    government_level: string
    branch_id: string
    branch: string
    party_id: string
    party: string
    election_status_id: string
    election_status: string
    office_type_id: string
    office_title: string
    has_avatar: boolean
    has_campaign_image: boolean
    campaign_name?: any
    campaign_quote?: any
    state_code: string
    state_name: string
    county?: any
    city?: any
    section?: any
    district?: any
    district_id?: any
    zips?: any
}

function buildCandidateColorMap(candidates): Map<string, Color> {
    const cardColor = new Map()
    const colorIterator = new ColorBlendIterator(
        [66, 66, 66],
        [160, 160, 160],
        3
    )

    cardColor.set(2, colorIterator.next())
    cardColor.set(1, colorIterator.next())
    cardColor.set(0, colorIterator.next())
    return cardColor
}

function makeCandidateCards(
    candidates: Candidate[] | null,
    districts: Map<string, DistrictInfo>,
    ballotDate: string
): JSX.Element[] | JSX.Element {
    if (candidates === null) {
        // return <p style={{fontSize: "5rem"}}>Loading...</p>
        return (
            <img
                width={100}
                height={100}
                src="/img/loading-icon.gif"
                alt="loading"
            />
        )
    }

    // Remove duplicates from candidates while preserving array order
    // This can happen when one candidate has multiple election_ids
    const candidateIDSet = new Set()
    const candidatesNoDuplicates: Candidate[] = []
    candidates
        .filter((candidate) => candidate.election_date === ballotDate)
        .forEach((candidate) => {
            // Check if this is a duplicate
            if (candidateIDSet.has(candidate.candidate_id)) {
                return
            }

            candidatesNoDuplicates.push(candidate)

            // Keep track of the ids we've already seen
            candidateIDSet.add(candidate.candidate_id)
        })

    // Arrange the candidates by government level
    candidatesNoDuplicates.sort((a: Candidate, b: Candidate): number => {
        return a.government_level_id > b.government_level_id ? -1 : 1
    })

    const colorMap = buildCandidateColorMap(candidatesNoDuplicates)
    return candidatesNoDuplicates.map((candidate, index) => (
        <BallotCard
            key={candidate.candidate_id}
            color={colorMap.get((candidate.government_level_id - 1) as any)}
            index={index}
            {...(candidate as any)}
            district={districts.get(candidate.district_id)}
        />
    ))
}

export interface DistrictInfo {
    lat?: string
    lng?: string
    state?: string
    county?: string
    name: string
    id?: string
}

interface BallotState {
    ballot: any
    blur: number
    tabletMode: boolean
    user: any
    follow: boolean
    districts: Map<string, DistrictInfo>
}

interface BallotProps {
    ballotId: string
    ballotDate: string
}

/**
 * Correct malformatted GeoJSON from the r3 district API.
 *
 * This is to correct an issue with our API where malformatted GeoJSON is returned.
 * Specifically, a type: Polygon is returned with rings (coordinate loops) that should instead be separarate polygons in a type: MultiPolygon
 * Accoring to the GeoJSON spec, for polygon with multiple rings, the first ring must be an exterior ring, and subsequent rings are interior rings (essentially, holes.)
 * Our API instead erroneously returns polygons with many exterior rings.
 * See https://datatracker.ietf.org/doc/html/rfc7946#section-3.1.6
 *
 * This may need to be removed in the future if our API is ever corrected.
 * @param polygon
 * @returns
 */
function polyHackRemoveMe(polygon): any {
    return {
        type: "MultiPolygon",
        coordinates: [...polygon.coordinates.map((coord) => [coord])],
    }
}

async function getDistrictInfoFromCandidateInfo(
    candidates: Candidate[]
): Promise<Map<string, DistrictInfo>> {
    const promises = candidates.map(async (candidate) => {
        // Get district polygon info
        const district = candidate.district_id
        const name = candidate.district
        if (district === null) {
            return null
        }
        const data = await api.getDistrict(district, "1")
        let polygon = JSON.parse(data.payload[0].polygon)

        // Hack to fix API data
        if (polygon.coordinates.length > 1) {
            polygon = polyHackRemoveMe(polygon)
        }
        console.log(polygon)
        let lng, lat
        if (polygon.type === "MultiPolygon") {
            // Find the polygon with the biggest area
            const coordinates = polygon.coordinates as any[]
            const biggest = coordinates
                .map((polygon) =>
                    geoArea({ coordinates: polygon, type: "Polygon" })
                )
                .reduce(
                    (prevIndex, currentArea, currentIndex, areas) =>
                        currentArea > areas[prevIndex]
                            ? currentIndex
                            : prevIndex,
                    0
                )
            ;[lng, lat] = polylabel(polygon.coordinates[biggest])
        } else if (polygon.type === "Polygon") {
            ;[lng, lat] = polylabel(polygon.coordinates)
        } else {
            throw new Error(
                "invalid polygon type, should be Polygon or MultiPolygon"
            )
        }
        // Convert district polygon info into a single lng/lat

        console.log(lng, lat)
        const state = data.payload[0].state
        if (
            data.payload[0].county !== undefined &&
            data.payload[0].county !== null
        ) {
            const county = data.payload[0].county
            const districtInfo: DistrictInfo = {
                lat,
                lng,
                state,
                county,
                name,
                id: district,
            }
            return districtInfo
        }

        // Get county at arbitrary lng/lat
        const res = await api.getDistrictsByLngLat(lng, lat, 0)
        const county = res.payload.find(
            (district) => district.type === "County District"
        )
        if (county === undefined) {
            console.error(
                { getDistrictData: data, getDistrictsByLngLatRes: res },
                "County not found"
            )
        }
        const districtInfo: DistrictInfo = {
            lat,
            lng,
            state,
            county: county.name,
            name,
            id: district,
        }
        return districtInfo
    })
    const infos = await Promise.all(promises)
    const districts = new Map<string, DistrictInfo>()
    infos
        .filter((info) => info !== null)
        .forEach((info: DistrictInfo) => {
            if (info.id === undefined) {
                console.error({ info }, "District had no ID")
                return
            }
            districts.set(info.id, info)
        })
    return districts
}

function Ballot({ ballotId, ballotDate }: BallotProps): JSX.Element {
    const ballotRef = useRef<HTMLDivElement>(null)
    const [state, setState]: [BallotState, any] = useState({
        ballot: null,
        blur: 0,
        tabletMode: false,
        user: null,
        follow: false,
        districts: new Map<string, DistrictInfo>(),
    })

    // Get district by ID with polygon:
    // http://localhost:3000/api/voting_districts/?district_id=34625&polygon=1
    // Get district by FIPS:
    // http://localhost:3000/api/voting_districts/?location_geo_id=47093&polygon=0
    // Get districts by lng/lat
    // https://ballotbuilder.com/api/voting_districts?lat=35.95397026476914&lon=-83.92537955083542
    // {payload: [{type_id: "10", county: "knox"}]}
    useEffect(() => {
        api.getBallot(ballotId)
            .then(async ({ payload }) => {
                console.log(payload)
                {
                    // Set districts with only names

                    const districts = new Map<string, DistrictInfo>()
                    payload.candidates.forEach((candidate) => {
                        const districtId = candidate.district_id
                        const districtName = candidate.district
                        districts.set(districtId, {
                            name: districtName,
                            id: districtId,
                        })
                    })
                    setState((oldState) => {
                        const newState = {
                            ...oldState,
                            districts,
                            ballot: payload,
                        }
                        return newState
                    })
                }
                {
                    // Set districts with all info

                    const districts = await getDistrictInfoFromCandidateInfo(
                        payload.candidates
                    )
                    console.log(districts)
                    setState((oldState) => {
                        const newState = {
                            ...oldState,
                            districts,
                        }
                        return newState
                    })
                }
            })
            .catch((err) => {
                console.error(err)
            })
        api.get("user")
            .then(async (response) => await response.json())
            .then(({ payload }) => {
                setState((oldState) => {
                    const newState = {
                        ...oldState,
                        user: payload,
                    }
                    return newState
                })
            })
            .catch((err) => {
                console.error(err)
            })

        // Set initial tablet mode state
        const tabletQuery = window.matchMedia("(max-width: 1280px)")
        const tabletMode = tabletQuery.matches
        setState((oldState) => {
            const newState = {
                ...oldState,
                tabletMode,
            }
            return newState
        })
    }, [])

    const handleScroll = (event, tabletMode: boolean | null = null): void => {
        console.log("handleScroll", tabletMode, state.tabletMode)
        let blur: number

        // 0 <= blur <= 4
        // blur is in px
        blur = Math.min(
            4,
            Math.max(0, (event.currentTarget?.scrollTop || 0) / 100)
        )
        if (!(tabletMode === null ? state.tabletMode : tabletMode)) {
            blur = 0
        }
        // Short circuit if state doesn't need to be changed
        if (blur === state.blur) {
            return
        }
        setState((oldState) => {
            return {
                ...oldState,
                blur,
            }
        })
    }

    // If I put the handleWindowResize logic in the other useEffect block, it won't properly update state
    useEffect(() => {
        // Setup window resize handler
        const handleWindowResize = (): void => {
            const tabletQuery = window.matchMedia("(max-width: 1280px)")
            const tabletMode = tabletQuery.matches
            // Short circuit if state doesn't need to change
            if (tabletMode === state.tabletMode) {
                return
            }
            setState((oldState) => {
                const newState = {
                    ...oldState,
                    tabletMode,
                }
                return newState
            })
            // TODO: Check if calling handleScroll here causes 2 re-renders
            // If so, refactor so we can bundle the state change in handleScroll with the state change from handleWindowResize
            // I'm noticing some sluggishness and choppiness while scrolling, especially on my phone, but it could just be my imagination
            handleScroll({ currentTarget: ballotRef?.current }, tabletMode)
        }

        window.addEventListener("resize", handleWindowResize)
        return () => {
            // Clean up
            // Remove resize listener
            window.removeEventListener("resize", handleWindowResize)
        }
    })

    // get user following status for this ballot
    useEffect(() => {
        if (state.user) {
            api.get("ballot/follow?ballot_id=" + ballotId)
                .then(async (_) => await _.json())
                .then((res) => {
                    setState((oldState) => {
                        const newState = {
                            ...oldState,
                            follow: res.payload.follow,
                        }
                        return newState
                    })
                })
        }
    }, [state.user])

    if (state.ballot) {
        return (
            <div className={styles.border}>
                <div className={styles.frame}>
                    <div
                        className={styles.background}
                        style={{ filter: `blur(${state.blur}px)` }}></div>
                    <a href="/" className={styles.globeLink}>
                        <div
                            className={styles.spacer}
                            style={{ filter: `blur(${state.blur}px)` }}>
                            <div className={styles.earthAndMoon}>
                                <div className={styles.earth}>
                                    <img
                                        className={styles.globeFlare}
                                        src="/assets/ballot/Flare.png"
                                        alt="Globe"
                                        style={{
                                            objectFit: "contain",
                                            // objectPosition: "left center",
                                        }}
                                    />
                                    <img
                                        src="/assets/ballot/Globe_512.png"
                                        alt="Globe"
                                        style={{
                                            objectFit: "contain",
                                            // objectPosition: "left center",
                                        }}
                                    />
                                    <img
                                        className={styles.globeRing}
                                        src="/assets/globe_ring_small.png"
                                        alt="Globe"
                                        style={{
                                            objectFit: "contain",
                                            // objectPosition: "left center",
                                        }}
                                    />
                                </div>
                                <div className={styles.moon}>
                                    <img
                                        src="/assets/ballot/Moon_256.png"
                                        alt="Moon"
                                        style={{
                                            objectFit: "contain",
                                            objectPosition: "center 20%",
                                        }}
                                    />
                                </div>
                            </div>
                            <div className={styles.logo}>
                                <div className={styles.logoSizer}>
                                    <img
                                        className={styles.logoimg}
                                        src="/assets/ballot/BB_logo_white.svg"
                                        alt="Ballot Builder Logo"
                                    />
                                </div>
                            </div>
                        </div>
                    </a>
                    <div
                        className={styles.ballot}
                        onScroll={handleScroll}
                        ref={ballotRef}>
                        <div className={styles.root}>
                            {/* <Helmet>
                                <html className={styles.html} />
                                <body className={styles.body} />
                                <base target="_parent"></base>
                            </Helmet> */}
                            <a href="/" className={styles.candidatesSpacer}></a>
                            <div className={styles.candidatesContainer}>
                                {makeCandidateCards(
                                    state.ballot.candidates,
                                    state.districts,
                                    ballotDate
                                )}
                            </div>
                        </div>
                    </div>
                    <div className={styles.innerShadow}></div>
                </div>
                <div className={styles.footer}>
                    <a href="/">BALLOTBUILDER.COM</a>
                </div>
            </div>
        )
    }
    return <div className={styles.placeholderRoot} />
}

export default Ballot
