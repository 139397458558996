import Cookie from "./cookie.js"

const getCookie = Cookie.getCookie

const api = {}

// api.host = window.location.hostname; // Dev
api.base_url = "/api/"
api.DEBUG = false

api.get = function (api_url, candidate_token = false) {
    if (api.DEBUG) {
        console.log("API URL: " + api_url)
    }

    return fetch(api.base_url + api_url, {
        method: "GET",
        headers: {
            Authorization: 'Bearer '+getCookie(candidate_token? 'candidate_session_token':'session_token')
        },
    })
}

api.post = function (api_url, post_params, candidate_token = false)
{
    return fetch(api.base_url + api_url, {
        method: 'POST',
        body: JSON.stringify(post_params),
        headers: {
            Authorization: 'Bearer '+getCookie(candidate_token? 'candidate_session_token':'session_token')
        }
    });
};

api.put = function (api_url, put_params, candidate_token = false)
{
    return fetch(api.base_url + api_url, {
        method: 'PUT',
        body: JSON.stringify(put_params),
        headers: {
            Authorization: 'Bearer '+getCookie(candidate_token? 'candidate_session_token':'session_token')
        }
    });
};

api.delete = function (api_url, candidate_token = false)
{
    return fetch(api.base_url + api_url, {
        method: 'DELETE',
        headers: {
            Authorization: 'Bearer '+getCookie(candidate_token? 'candidate_session_token':'session_token')
        }
    });
};

api.fill_dropdown = function (dropdown_selector) {
    return function (response) {
        const names = response.payload

        $(dropdown_selector).find('option[value!=""]').remove()
        let options = $(dropdown_selector).html()

        for (id in names)
            options += '<option value="' + id + '">' + names[id] + "</option>"

        $(dropdown_selector).html(options)
    }
}

api.user_avatar_url = function (user_id) {
    return api.host + "/user/avatar/" + user_id
}

// Converts a JS object into a string that the API will understand
api.format = function (obj) {
    if (typeof obj === "undefined") {
        return
    }
    if (obj instanceof Date) {
        return (
            obj.getFullYear() +
            "-" +
            (obj.getMonth() + 1) +
            "-" +
            (obj.getDay() + 1)
        )
    }
    if (typeof obj === "string") {
        // Replace underscores with spaces
        return obj.replace(/_/g, " ")
    }
    if (obj.toString) {
        return obj.toString()
    }
}

// Converts a parameter object into a URI search string
// Runs every value through api.format and uses its key as the search parameter name
api.params_to_search = function (params) {
    const url = new URL(api.host)

    for (const item in params) {
        if (typeof params[item] === "undefined") continue

        url.searchParams.append(item, api.format(params[item]))
    }

    return url.search
}

// Parameters
// start_date - date
// end_date - date
// state - 2-character state abbreviation or state ID
// county - name or ID
// city - name with spaces or ID
// zip
// include_candidates

api.search_elections = function (params) {
    const search_string = api.params_to_search(params)

    return api
        .get(decodeURIComponent("elections" + search_string))
        .then((response) => response.json())
}

// Parameters
// start_date
// end_date
// zip
// state
// government_level_id

api.get_election_counts_by_zip = function (params) {
    const search_string = api.params_to_search(params)

    return api
        .get(decodeURIComponent("election/counts_by_zip/" + search_string))
        .then((response) => response.json())
}

// Parameters
// start_date
// end_date
// state
// government_level_id

api.get_election_counts_by_state = function (params) {
    const search_string = api.params_to_search(params)

    return api
        .get(decodeURIComponent("election/counts_by_state/" + search_string))
        .then((response) => response.json())
}

// Parameters
// start_date
// end_date
// state
// county_id
// government_level_id

api.get_election_counts_by_county = function (params) {
    const search_string = api.params_to_search(params)

    return api
        .get(decodeURIComponent("election/counts_by_county/" + search_string))
        .then((response) => response.json())
}

// Parameters
// code

api.get_counties_for_state_by_code = function (params) {
    const code = params.code

    return api
        .get(decodeURIComponent("county/by_state/" + code))
        .then((response) => response.json())
    // .catch( err => {
    // 	console.warn('API error: ', err)
    // });
}

api.get_states = function () {
    return api
        .get(decodeURIComponent("states"))
        .then((response) => response.json())
}

// Parameters
// state
// county
// name

api.city_search = function (params) {
    const search_string = api.params_to_search(params)

    return api
        .get(decodeURIComponent("cities" + search_string))
        .then((response) => response.json())
}

// Parameters
// start_date
// end_date
// city (name or id. If name, must be exact match)
// state (required if specifying by name)
// government_level_id

api.get_election_counts_by_city = function (params) {
    const search_string = api.params_to_search(params)
    console.log("search_string", search_string)
    return api
        .get(decodeURIComponent("election/counts_by_city" + search_string))
        .then((response) => response.json())
}

// Dummy function for an unimplemented API call
// TODO: Hook this up to the API when the call is finshed
api.get_schoolbards = function (params) {
    const dummy_data = {
        status: 200,
        payload: [
            { id: 1010, name: "test board 1010" },
            { id: 1011, name: "test board 1011" },
            { id: 1100, name: "test board 1100" },
        ],
    }

    return Promise.resolve(dummy_data)
}

/**
 * Get a ballot by ID
 *
 * @param ballotid - R3 Ballot ID
 * @returns Data for the requested ballot
 */
api.getBallot = async function (ballotid) {
    const endpoint = `my_ballot?ballot_id=${ballotid}`
    try {
        const response = await api.get(endpoint)
        console.info({ response, endpoint }, "got ballot api response")
        return response.json()
    } catch (err) {
        console.error({ endpoint }, "could not get ballot from r3 api")
        throw err
    }
}

api.getDistrict = async function(districtId, polygon) {
    const endpoint = `voting_districts/?district_id=${districtId}&polygon=${polygon}`
    try {
        const response = await api.get(endpoint)
        console.info({ response, endpoint }, "got district response")
        return response.json()
    } catch (err) {
        console.error({ endpoint }, "could not get district from api")
        throw err
    }
}

api.getDistrictsByLngLat = async function(lng, lat, polygon) {
    const endpoint = `voting_districts/?lat=${lat}&lon=${lng}&polygon=${polygon}`
        // https://ballotbuilder.com/api/voting_districts?lat=35.95397026476914&lon=-83.92537955083542
        try {
            const response = await api.get(endpoint)
            console.info({ response, endpoint }, "got district response")
            return response.json()
        } catch (err) {
            console.error({ endpoint }, "could not get districts from api")
            throw err
        }

}

export default api
