/* eslint-disable @typescript-eslint/naming-convention */
import CandidateName from "../lib/CandidateName"
import { type DistrictInfo } from "./Ballot"
import * as tempStyles from "./BallotCard.module.css"
// import Image from "next/image"
import React from "react"

// Workaround for https://github.com/mrmckeb/typescript-plugin-css-modules/issues/16#issuecomment-1777990377
type Styles = Readonly<Record<string, string>>
const styles = tempStyles as unknown as Styles

export interface BallotCardProps {
    // TODO: Coordinate with DB team to make type interfaces
    color: any
    office_title: any
    picture: any
    initials: any
    index: any
    has_avatar: any
    name: any
    first_name: any
    middle_name: any
    last_name: any
    candidate_id: any
    election_id: any
    government_level: any
    district?: DistrictInfo
}
// ex. http://localhost:3000/?lng=-84.03676660309127&lat=36.01354351019111&county=Knox&state=Tennessee&district_id=null&election_ilter=null&election_type=0
function districtHRef(district: DistrictInfo): string {
    if (district.county === undefined) {
        return ""
    }
    const { lng, lat, county, state, id: district_id } = district
    const countyCorrected = county.replaceAll(" ", "_")
    const params = { lng, lat, county: countyCorrected, state, district_id }
    const searchParams = new URLSearchParams(params as any)
    return `/?${searchParams.toString()}`
}

// A single candidate card on the ballot
export default function BallotCard({
    color,
    office_title,
    picture,
    initials,
    index,
    has_avatar,
    name,
    first_name,
    middle_name,
    last_name,
    candidate_id,
    election_id,
    district,
}: BallotCardProps): JSX.Element {
    const officeHref = `/pages/election-candidates?election_id=${
        election_id as number
    }`
    const candidateHref = `/pages/election-candidates/candidate.php?candidate_id=${
        candidate_id as number
    }&election_id=${election_id as number}`

    const candidateName = new CandidateName()
    if (middle_name === undefined) middle_name = ""
    if (name !== undefined) {
        candidateName.set(name)
    } else {
        candidateName.set(first_name, middle_name, last_name)
    }

    // Photo content defaults to a <p> containing the candidate's initials
    let photoContent = (
        <p className={styles.initials}>{candidateName.firstLastInitials}</p>
    )
    if (has_avatar as boolean) {
        // If we have an avatar, use that instead
        photoContent = (
            <img
                src={`/api/candidate/${
                    candidate_id as number
                }/avatar?thumbnail=1`}
                alt={`${first_name as string}'s avatar`}
                style={{ borderRadius: "100%" }}
            />
        )
    }

    return (
        <div
            className={styles.candidateCard}
            style={{ animationDelay: `${index * 20}ms` }}>
            <a href={officeHref} className={styles.officeLink}>
                <div
                    className={styles.topBar}
                    style={{
                        background: `rgb(${color[0] as number}, ${
                            color[1] as number
                        }, ${color[2] as number})`,
                    }}>
                    <h3 className={styles.office}>{office_title}</h3>
                </div>
            </a>
            <div className={styles.content}>
                <a className={styles.candidatePageLink} href={candidateHref}>
                    <div
                        className={styles.photo}
                        style={{
                            background: `rgb(${color[0] as number}, ${
                                color[1] as number
                            }, ${color[2] as number})`,
                        }}>
                        {photoContent}
                    </div>
                    <p className={styles.name}>
                        <span>{candidateName.ballotName}</span>
                    </p>
                </a>
                <DistrictLink district={district} />
            </div>
        </div>
    )
}

function DistrictLink({ district }: { district?: DistrictInfo }): JSX.Element {
    console.log(district)
    if (district === undefined) {
        return <></>
    } else {
        return (
            <a href={districtHRef(district)} className={styles.districtLink}>
                <p className={styles.district}>{district.name}</p>
            </a>
        )
    }
}
