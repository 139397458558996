

.swiperContainer {
  --primary: #3a404c;
  --light: #f9f9fb;
  --gray: #a7a9ac;
  --swiper-navigation-size: 30px;
  --swiper-navigation-color: var(--light);
  /* --swiper-pagination-bottom: -10px; */
  --swiper-pagination-color: var(--light);
  --swiper-pagination-bullet-inactive-color: var(--gray);
  --swiper-pagination-bullet-inactive-opacity: 1;
}
.mySwipe {
  width: 100%;
  /* height: 100%; */
  height: 220px;

}

.mySlide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--primary);
  width: 100%;
  height: 100%;
}

.mySlide img {
  /* display: block;
  width: 100%;
  height: 100%; */
  max-height: 100%;
  object-fit: cover;
}

/* .mySlide div {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
} */

.mySlide .caption {
  position: absolute;
  z-index: 10;
  color: white;
  font-size: 12px;
  right: 5px;
  bottom: 5px;
  padding: 5px;
  border-radius: 5px;
  padding: 5px;
  background-color: #3a404c99;
}

.nextEl, .swiper-rtl .prevEl {
  right: var(--swiper-navigation-sides-offset, 10px);
  left: auto;
}

.prevEl, .swiper-rtl .nextEl {
  left: var(--swiper-navigation-sides-offset, 10px);
}

.nextEl, .prevEl {
  padding: 20px;
  border-radius: 100%;
  background-color: #3a404c99;
  position: absolute;
  top: var(--swiper-navigation-top-offset, 50%);
  width: calc(var(--swiper-navigation-size));
  height: var(--swiper-navigation-size);
  margin-top: calc(0px -(var(--swiper-navigation-size) / 2));
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--swiper-navigation-color, var(--swiper-theme-color));
}

.prevEl:after {
  content: 'prev';
}

.nextEl:after, .prevEl:after {
  font-weight: 900;
  font-family: swiper-icons;
  font-size: var(--swiper-navigation-size);
  text-transform: none !important;
  letter-spacing: 0;
  font-variant: initial;
  line-height: 1;
}

.swiperButtonDisabled {
  opacity: 30%;
}

.nextEl::after {
  transform: translateX(20%);
  content: 'next';
}

.prevEl::after {
  transform: translateX(-20%);
}

.snapshotPaginationHorizontal {
  margin-top: 4px;
  z-index: 10;
  position: relative;
  background-color: #3a404c;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 16px;
  line-height: 19px;

  /* left: 0; */
  /* width: 100%; */
}

.paginationBulletContainer {
  /* background-color: red; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.snapshotSwiperBullet {
  width: var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 8px));
  height: var(--swiper-pagination-bullet-height, var(--swiper-pagination-bullet-size, 8px));
  display: inline-block;
  border-radius: var(--swiper-pagination-bullet-border-radius, 50%);
  background: var(--swiper-pagination-bullet-inactive-color, #000);
  opacity: var(--swiper-pagination-bullet-inactive-opacity, .2);
  margin-left: 2px;
  margin-right: 2px;
}

.snapshotSwiperBulletActive {
  background: var(--light);
  opacity: 1;
}