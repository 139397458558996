import React, { useRef, useState, useEffect } from "react"
import BallotSnapshotSwiper from "./BallotSnapshotSwiper"
import styles from "./BallotShare.module.css"
import type Swiper from "swiper"

interface Props {
    ballotId: string
    ballotDates: string[]
}

export function BallotSharePopup({
    ballotId,
    ballotDates,
}: Props): JSX.Element {
    const [activeImg, setActiveImg] = useState<HTMLImageElement | null>(null)
    const [imageFile, setImageFile] = useState<File | null>(null)
    const [selectedDate, setSelectedDate] = useState<string>(
        ballotDates[0] ?? ""
    )

    const imageCache = useRef(new Map<string, File>())

    useEffect(() => {
        if (activeImg === null) return

        /**
         * Simple cached wrapper around fetch
         * @param url
         * @returns
         */
        async function getImageFile(url: string): Promise<File> {
            if (imageCache.current.has(url)) {
                return imageCache.current.get(url) as File
            }
            const res = await fetch(url)
            const blob = await res.blob()
            const file = new File([blob], "ballot.jpg", { type: blob.type })
            imageCache.current.set(url, file)
            return file
        }

        getImageFile(activeImg.src)
            .then((file) => {
                setImageFile(file)
            })
            .catch((err) => {
                console.error(err)
            })
    }, [activeImg])

    function swiperToActiveImg(swiper: Swiper): HTMLImageElement | null {
        const slide = swiper.slides[swiper.activeIndex]
        if (slide === undefined) {
            console.warn("active swiper slide was undefined")
            return null
        }
        const img = slide?.querySelector("img")
        if (img === null) {
            return null
        }
        return img !== undefined ? img : null
    }

    // useEffect(() => {}, [activeImage])

    function onSwiper(swiper: Swiper): void {
        const url = swiperToActiveImg(swiper)
        setActiveImg(url)
    }

    function onSlideChange(swiper: Swiper): void {
        const url = swiperToActiveImg(swiper)
        setActiveImg(url)
    }

    function ShareButton(): JSX.Element {
        if (navigator.canShare === undefined) {
            console.warn("Can't share anything")
            return <></>
        }
        const shareData: ShareData = {
            title: "Virtual Ballot",
            text: "Check out virtual ballot on Ballot Builder!",
            // url: `https://ballotbuilder.com/pages/ballot?ballot-id=${ballotId}`,
            files: imageFile !== null ? [imageFile] : undefined,
        }
        if (!navigator.canShare(shareData)) {
            console.warn(shareData, "Can't share")
            return <></>
        }
        return (
            <a
                className={styles.snapshotBottomButton}
                onClick={() => {
                    void navigator.share(shareData)
                }}>
                <img
                    src="/assets/icons/share-three-circles-connected.svg"
                    alt="share"
                />
            </a>
        )
    }

    return (
        <div className={styles.ballotSharePopup}>
            <h3
                className="blue"
                style={{
                    fontSize: "20px",
                    fontWeight: "700",
                }}>
                Share Your Ballot
            </h3>
            {ballotDates.length > 1 && (
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "20px",
                        marginTop: "-20px",
                    }}>
                    <select
                        onChange={(event) => {
                            setSelectedDate(event.target.value)
                        }}
                        style={{
                            alignItems: "flex-start",
                            backgroundColor: "var(--secondary-white-100)",
                            borderRadius: "8px",
                            display: "flex",
                            // minHeight: "36px",
                            // height: "30px",
                            marginTop: "4px",
                            padding: "8px",
                            border: "1px solid var(--primary-dark-grey10)",
                            color: "var(--primarydark-grey70)",
                            fontWeight: "400",
                            lineHeight: "24px",
                            // minWidth: "150px",
                            // appearance: "none",
                        }}>
                        {/* <option value="" disabled selected>
                            Select a ballot...
                        </option> */}
                        {ballotDates.map((date) => (
                            <option key={date} value={date}>
                                {date}
                            </option>
                        ))}
                    </select>
                </div>
            )}

            <span
                className="btn link clear delete"
                onClick={window.displayShare}>
                <img src="/assets/X.svg" />
            </span>
            <BallotShareButtonSuite
                ballotId={ballotId}
                ballotDate={selectedDate}
            />
            <BallotSnapshotSwiper
                ballotId={ballotId}
                ballotDate={selectedDate}
                onSwiper={onSwiper}
                onSlideChange={onSlideChange}
            />
            <div
                style={{
                    width: "100%",
                    marginTop: "20px",
                }}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "row",
                        alignItems: "center",
                        position: "relative",
                    }}>
                    <p style={{ fontFamily: "Doppio One" }}>Ballot Snapshot</p>
                    <div
                        style={{
                            display: "flex",
                            position: "absolute",
                            right: "0px",
                        }}>
                        <a
                            href={activeImg?.src}
                            download="ballot.jpg"
                            className={styles.snapshotBottomButton}>
                            <img src="/assets/icons/download.svg" alt="share" />
                        </a>
                        <ShareButton />
                    </div>
                </div>
            </div>
        </div>
    )
}

export function CandidateSharePopup({
    candidateName,
    candidateId,
}: {
    candidateName: string
    candidateId: string
}): JSX.Element {
    // http://localhost:3000/pages/election-candidates/candidate.php?candidate_id=416493
    return (
        <div className={styles.ballotSharePopup}>
            <h3 className="blue">Share Candidate</h3>
            <a className="btn link clear delete" onClick={window.displayShare}>
                <img src="/assets/X.svg" />
            </a>
            <ShareButtonSuite
                emailSubject={`Check out my candidate ${candidateName}!`}
                shareText={`Check out ${candidateName} on Ballot Builder!`}
                shareUrl={buildUrl({
                    base: `${window.location.origin}/pages/election-candidates/candidate.php`,
                    // base: "https://ballotbuilder.com/pages/election-candidates/candidate.php",
                    params: { candidate_id: candidateId },
                })}
            />
        </div>
    )
}

function BallotShareButtonSuite({
    ballotId,
    ballotDate,
}: {
    ballotId: string
    ballotDate: string
}): JSX.Element {
    return (
        <ShareButtonSuite
            emailSubject="Check out virtual ballot!"
            shareText="Check out virtual ballot on Ballot Builder!"
            shareUrl={ballotUrl(ballotId, ballotDate)}
        />
    )
}

function ShareButtonSuite({
    emailSubject,
    shareText,
    shareUrl,
}: {
    emailSubject: string
    shareText: string
    shareUrl: string
}): JSX.Element {
    return (
        <div className="flex-row btn-row">
            <TwitterShareButton shareText={shareText} shareUrl={shareUrl} />
            <FacebookShareButton shareUrl={shareUrl} />
            <LinkedInShareButton shareUrl={shareUrl} />
            <TruthSocialShareButton shareText={shareText} shareUrl={shareUrl} />
            <EmailShareButton
                subject={emailSubject}
                body={`${shareText} ${shareUrl}`}
            />
            <CopyLinkButton textToCopy={shareUrl} />
        </div>
    )
}

function fallbackCopyTextToClipboard(text): void {
    const textArea = document.createElement("textarea")
    textArea.value = text

    // Avoid scrolling to bottom
    textArea.style.top = "0"
    textArea.style.left = "0"
    textArea.style.position = "fixed"

    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()

    try {
        const successful = document.execCommand("copy")
        const msg = successful ? "successful" : "unsuccessful"
        console.log("Fallback: Copying text command was " + msg)
    } catch (err) {
        console.error("Fallback: Oops, unable to copy", err)
    }

    document.body.removeChild(textArea)
}

function copyTextToClipboard(text: string): void {
    console.log(text)
    if (navigator.clipboard === undefined || navigator.clipboard === null) {
        fallbackCopyTextToClipboard(text)
        return
    }
    navigator.clipboard.writeText(text).then(
        function () {
            console.log("Async: Copying to clipboard was successful!")
            window.customAlert("Link is copied to clipboard")
        },
        function (err) {
            console.error("Async: Could not copy text: ", err)
        }
    )
}

function ballotUrl(ballotId: string, ballotDate: string): string {
    // return encodeURIComponent(
    //     `https://ballotbuilder.com/pages/ballot?ballot-id=${ballotId}`
    // )
    return buildUrl({
        base: `${window.location.origin}/pages/ballot/`,
        // base: "https://ballotbuilder.com/pages/ballot/",
        params: { "ballot-id": ballotId, "ballot-date": ballotDate },
    })
}

function buildUrl({
    base,
    params,
}: {
    base: string
    params: Record<string, string>
}): string {
    const url = new URL(base)
    url.search = new URLSearchParams(params).toString()
    return url.href
}

/// Share URL spec

function twitterShareURL({ text, url }: { text: string; url: string }): string {
    return buildUrl({
        base: "http://x.com/share",
        params: { text, url },
    })
}

function linkedInShareURL({ shareUrl }: { shareUrl: string }): string {
    return buildUrl({
        base: "https://linkedin.com/sharing/share-offsite/",
        params: { url: shareUrl },
    })
}

function facebookShareURL({ shareUrl }: { shareUrl: string }): string {
    return buildUrl({
        base: "https://facebook.com/sharer/sharer.php",
        params: { u: shareUrl },
    })
}

// https://truthsocial.com/share?text=Check out my ballot on Ballot Builder!&url=https://ballotbuilder.com/pages/ballot?ballot-id=${ballotId}
function truthSocialShareURL({
    text,
    url,
}: {
    text: string
    url: string
}): string {
    return buildUrl({
        base: "https://truthsocial.com/share",
        params: { text, url },
    })
}

// href={`mailto:?subject=Checkout my ballot!&body=Check out my ballot on Ballot Builder! https://ballotbuilder.com/pages/ballot?ballot-id=${ballotId}`}>

function emailShareURL({
    subject,
    body,
}: {
    subject: string
    body: string
}): string {
    return buildUrl({
        base: "mailto:",
        params: { subject, body },
    })
}

/// Share buttons

function TwitterShareButton({
    shareText,
    shareUrl,
}: {
    shareText: string
    shareUrl: string
}): JSX.Element {
    const href = twitterShareURL({ text: shareText, url: shareUrl })
    return (
        <a
            className={styles.shareButton}
            target="_blank"
            href={href}
            rel="noreferrer">
            <img src="/img/x-logo-dark-blue.svg" />
            <span className="blue uppercase">X</span>
        </a>
    )
}

function LinkedInShareButton({ shareUrl }: { shareUrl: string }): JSX.Element {
    return (
        <a
            target="_blank"
            href={linkedInShareURL({ shareUrl })}
            className={styles.shareButton}
            rel="noreferrer">
            <img src="/assets/linkedin.svg" />
            <span className="blue uppercase">linkedin</span>
        </a>
    )
}

function TruthSocialShareButton({
    shareText,
    shareUrl,
}: {
    shareText: string
    shareUrl: string
}): JSX.Element {
    const href = truthSocialShareURL({ text: shareText, url: shareUrl })
    return (
        <a
            className={styles.shareButton}
            target="_blank"
            href={href}
            rel="noreferrer">
            <img src="/assets/icons/truthsocial.svg" />
            <span className="blue uppercase">truth social</span>
        </a>
    )
}

function FacebookShareButton({ shareUrl }: { shareUrl: string }): JSX.Element {
    return (
        <a
            className={styles.shareButton}
            target="_blank"
            href={facebookShareURL({ shareUrl })}
            rel="noreferrer">
            <img src="/assets/facebook.svg" />
            <span className="blue uppercase">facebook</span>
        </a>
    )
}

function EmailShareButton({
    subject,
    body,
}: {
    subject: string
    body: string
}): JSX.Element {
    return (
        <a
            className={styles.shareButton}
            href={emailShareURL({ subject, body })}>
            <img src="/assets/mail.svg" />
            <span className="blue uppercase">email</span>
        </a>
    )
}

function CopyLinkButton({ textToCopy }: { textToCopy: string }): JSX.Element {
    return (
        <a
            // href="#"
            className={styles.shareButton}
            onClick={() => {
                copyTextToClipboard(textToCopy)
            }}>
            <img src="/assets/copy.svg" />
            <span className="blue uppercase">Copy Link</span>
        </a>
    )
}
