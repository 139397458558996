/* Handle name formatting */
export default class CandidateName {
    constructor() {
        this.ballotName = "";
        this.firstLastInitials = "";
    }

    setFromFirstMiddleLast(first, middle, last) {
        // Calculate middle initial
        let middleInitial = "";
        if (middle !== undefined && middle !== null) {
            if (middle.length === 1) {
                middleInitial = middle;
            } else if (middle.length > 1) {
                middleInitial = middle[0];
            } // if no middle, middleInitial = ""
        }


        // Format ballot name as in Richard M. Briggs
        this.ballotName = `${first} ${middleInitial ? middleInitial + '.' : ''} ${last}`;

        // Format initials as in RB
        this.firstLastInitials = first[0] + last[0];
    }

    // Set from a string formatted such as Richard M. Briggs
    setFromBallotName(ballotName) {
        const words = ballotName.split(' ');
        if (words.length >= 2) {
            this.firstLastInitials = words[0][0] + words[words.length - 1][0];
        }
        this.ballotName = ballotName;
    }

    // Set name, attempting to automatically detect the name format
    set(...argv) {

        // Ballot name case
        if (argv.length === 1 && typeof argv[0] === "string") {
            this.setFromBallotName(argv[0]);
            return;
        }

        let first, middle, last;

        if (argv.length === 1 && typeof argv[0] === "object") {
            // Case {first: "Richard", middle: "Madison", last: "Briggs"}
            ({first, middle, last} = argv[0]);
        } else if (argv.length === 2) {
            // First and last individual args case
            first = argv[0];
            middle = '';
            last = argv[1];
        }else if (argv.length === 3) {
            // First middle last individual args case
            first = argv[0];
            middle = argv[1];
            last = argv[2];
        }

        this.setFromFirstMiddleLast(first, middle, last);
        // return this;
    }
}