import React from "react"

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/swiper-react"

// Import Swiper styles
// import "swiper/swiper.css"
// import "swiper/modules/navigation.css"
// import "swiper/modules/pagination.css"

import styles from "./BallotShareSwiper.module.css"

// import required modules
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper/modules"

interface Props {
    ballotId: string
    ballotDate: string
    onSwiper: (arg0: any) => void
    onSlideChange: (arg0: any) => void
}

export default function BallotSnapshotSwiper({
    ballotId,
    ballotDate,
    onSwiper,
    onSlideChange,
}: Props): JSX.Element {
    return (
        <>
            <div className={styles.swiperContainer}>
                <Swiper
                    onSwiper={onSwiper}
                    onSlideChange={onSlideChange}
                    navigation={{
                        nextEl: `.${styles.nextEl}`,
                        prevEl: `.${styles.prevEl}`,
                        disabledClass: styles.swiperButtonDisabled,
                    }}
                    pagination={{
                        enabled: true,
                        el: "#snapshot-pagination",
                        horizontalClass: styles.snapshotPaginationHorizontal,
                        bulletClass: styles.snapshotSwiperBullet,
                        bulletActiveClass: styles.snapshotSwiperBulletActive,
                    }}
                    mousewheel={true}
                    keyboard={true}
                    lazy={true}
                    modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                    className={styles.mySwipe}>
                    <div className={styles.nextEl}></div>
                    <div className={styles.prevEl}></div>
                    <SwiperSlide className={styles.mySlide}>
                        <img
                            src={`https://ballotbuilder.com/ballotpreview/${ballotId}?ballotDate=${ballotDate}`}
                            alt=""
                            loading="lazy"
                        />
                        <div className={styles.caption}>2 × 3</div>
                    </SwiperSlide>
                    <SwiperSlide className={styles.mySlide}>
                        <img
                            src={`https://ballotbuilder.com/ballotpreview/${ballotId}/2x2?ballotDate=${ballotDate}`}
                            alt=""
                            loading="lazy"
                        />
                        <div className={styles.caption}>2 × 2</div>
                    </SwiperSlide>
                    <SwiperSlide className={styles.mySlide}>
                        <img
                            src={`https://ballotbuilder.com/ballotpreview/${ballotId}/3x3?ballotDate=${ballotDate}`}
                            alt=""
                            loading="lazy"
                        />
                        <div className={styles.caption}>3 × 3</div>
                    </SwiperSlide>
                    <div></div>
                </Swiper>
                <div className={styles.paginationBulletContainer}>
                    <div id="snapshot-pagination"></div>
                </div>
            </div>
        </>
    )
}
