/* Iterates between two colors */
export default class ColorBlendIterator {
    constructor(start, end, steps) {
        this.start = start;
        this.end = end;
        this.steps = steps;
        this.currentStep = -1;
    }

    lerp(a, b, amount) {
        return (1 - amount) * a + amount * b;
    }

    lerpColor(a, b, amount) {
        // Lerp each element in the arrays
        return a.map((v, i) => this.lerp(v, b[i], amount))
    }

    /**
     * Get the next color
     * @returns {Array} next color
     */
    next() {
        this.currentStep++;
        return this.lerpColor(this.start, this.end, this.currentStep / (this.steps - 1));
    }

}