/* candidate cards */

@keyframes pop-in {
    0% {
        opacity: 0;
    }

    1% {
        transform: scale(80%);
        opacity: 0.5;
    }

    100% {
        transform: scale(100%);
        opacity: 1;
    }
}

.candidateCard {
    animation-name: pop-in;
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.1);
    ;
    animation-duration: 0.4s;
    animation-fill-mode: both;

    width: 15.0rem;
    height: 15.0rem;
    background: transparent;
    border-radius: 1.7999999999999998rem 0 0 0;
    margin: 2.1rem 0 2.1rem 0.0rem;
    box-shadow: 0.3rem 0.3rem 1.7999999999999998rem rgba(0, 0, 0, 0.50);
    margin-left: 1.2rem;
    margin-right: 1.2rem;
}

.candidateCard .topBar {
    /* background: rgb(17, 48, 61); */
    position: relative;
    height: 25%;
    display: flex;
    border-radius: 1.7999999999999998rem 0 0 0;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    transition: box-shadow 0.25s ease-out;
}

.officeLink .topBar::after {
    content: '»';
    position: absolute;
    color: white;
    font-size: 1.7999999999999998rem;
    right: -3.0rem;
    top: 0.39rem;
    opacity: 0;
    transition: all 0.25s ease-out;
    pointer-events: none;
}

.officeLink:hover .topBar::after {
    right: 0.6rem;
    opacity: 1;
}

.officeLink:hover .office {
    right: 0.3rem;
}

.candidateCard .office {
    margin: auto;
    position: relative;
    font-family: Helvetica, sans-serif;
    font-size: 1.14rem;
    color: white;
    right: 0;
    transition: right 0.25s ease-out;
}

.candidateCard .content {
    height: 75%;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.candidateCard .candidatePageLink {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.candidatePageLink:hover .name {
    /* text-decoration: underline; */
}



.candidatePageLink:hover .photo {
    box-shadow: 0 0 0.6rem rgba(0, 0, 0, 0.5);
}

.photo img {
    position: absolute;
    height: 100%;
    width: 100%;
    inset: 0px;
    object-fit: contain;
    color: transparent;
}

.candidateCard .photo {
    width: 4.2rem;
    height: 4.2rem;
    border-radius: 100%;
    /* background: rgb(17, 48, 61); */
    margin-top: 1.5rem;
    margin-bottom: 0.36rem;
    display: flex;
    justify-content: center;
    align-items: center;
    /* next/image requires position:relative on the parent element when the fill option is used */
    position: relative;
}

.photo .initials {
    color: white;
    font-weight: bold;
    font-family: Helvetica, sans-serif;
    font-size: 1.9799999999999998rem;
}

.candidateCard .name {
    line-height: 1.26rem;
    position: relative;
    margin-top: 1.14rem;
    margin-bottom: 0.24rem;
    font-size: 1.5rem;
    letter-spacing: 0.02em;
    color: rgb(100, 100, 100);
    font-family: Baskerville;

    /* display: flex;
    align-items: center;
    justify-content: center; */
}

.candidatePageLink .name span::after {
    content: '';
    position: absolute;
    top: 110%;
    display: block;
    width: 0%;
    height: 0.12rem;
    background: rgb(100, 100, 100);
    transition: all 0.25s;
}

.candidatePageLink:hover .name span::after {
    width: 100%;
}

/* .underlineOnHover {
    position: relative;
}

.underlineOnHover::after {
    content: '';
    position: absolute;
    top: 78%;
    display: block;
    width: 0%;
    height: 0.12rem;
    background: rgb(100, 100, 100);
    transition: all 0.25s;
}

.underlineOnHover:hover::after {
    width: 100%;
} */

.candidateCard .district {
    margin: 0;
    font-family: Helvetica, sans-serif;
    font-size: 1.02rem;
    letter-spacing: 0;
    color: rgb(160, 160, 160);
    text-decoration-color: rgb(160, 160, 160);
}

.districtLink:hover {
    color: rgb(160, 160, 160);
    text-decoration: underline;
    /* cursor: pointer; */
}