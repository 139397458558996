.container {
    width: 100vw;
    height: 100vh;
}

.border {
    width: 100%;
    height: 100%;
    position: relative;
    background: rgb(57, 57, 57);
    display: flex;
    flex-direction: column;
}

.frame {
    position: relative;
    height: calc(100% - 3.0rem);
    margin-left: 0.8999999999999999rem;
    margin-right: 0.8999999999999999rem;
    overflow: hidden;
    border-radius: 0;
    box-shadow: 0.0rem 0.0rem 2.4rem rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap; */
    justify-content: center;
    flex-shrink: 0;
    flex-grow: 0;
    z-index: 1;
    /* overflow-x: hidden; */
}

.background {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: url('../html/assets/textures/blue-starry-bg.jpg');
    background-size: cover;
    background-position: center;
}

.spacer {
    margin-left: 1.2rem;
    min-width: 36.0rem;
    min-height: 24.0rem;
    max-width: 48.0rem;
    height: 70%;
    width: 30%;
    align-self: center;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
}

.globeLink {
    display: flex;
}

.innerShadow {
    box-shadow: inset 0.0rem 0.0rem 2.4rem rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    /* Must match .frame */
    border-radius: 0;
    pointer-events: none;
}

.ballot {
    position: relative;
    height: 100%;
    padding: 0;
    overflow-y: auto;
    overflow-x: hidden;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

/* Hide scrollbar on smaller devices */
@media (max-width: 37.199999999999996rem) {

    /* Hide scrollbar for IE, Edge and Firefox */
    .ballot {
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
        /* border-radius: 0 0 3.0rem 3.0rem; */
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    .ballot::-webkit-scrollbar {
        display: none;
    }
}

.root {
    font-family: Baskerville;
    letter-spacing: 0.04em;
    text-align: center;
    left: 0;
    top: 0;
    /* min-height: 60.0rem; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* min-height: 100%; */
}

.placeholderRoot {
    height: 100%;
    width: 100%;
    background: rgb(180, 180, 182);
}

.banner {
    position: absolute;
    width: 2560px;
    margin: 0 calc((2560px - 100%) / 2);
    left: calc(-2560px + 100%);
    top: -100px;
}

.earthAndMoon {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 70%;
    width: 100%;
    /* background: blue; */
}

.earth {
    position: relative;
    /* background-color: rgba(200, 50, 50, 0.4); */
    width: 70%;
    max-height: 100%;
    /* margin: 1.2rem; */
    /* height: 65%;
    max-height: 36.0rem;
    max-width: 100%;
    margin: 1.2rem; */
    aspect-ratio: 1 / 1;
}

.earth img,
.moon img,
.logoSizer img {
    position: absolute;
    height: 100%;
    width: 100%;
    inset: 0px;
    object-fit: contain;
    color: transparent;
}

@keyframes spin {
    from {
        transform: rotate(0deg)
    }

    to {
        transform: rotate(360deg);
    }
}

.globeRing {
    animation: spin 8s linear infinite;
}

.globeFlare {
    transform: translate(-25%, -10%) scale(2) rotate(60deg)
}

.moon {
    position: relative;
    /* left: 10%; */
    /* top: 50%; */
    /* margin:1.2rem; */
    width: 15%;
    height: 100%;
    /* max-height: 100%; */
    /* margin: 1.2rem; */
    /* background-color: blanchedalmond; */
    /* margin: 1.2rem;
    height: 20%;
    max-height: 18.0rem;
    max-width: 100%; */
    /* aspect-ratio: 1 / 1; */
}

.logo {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 30%;
}

.logoSizer {
    position: relative;
    width: 80%;
}

.logoImage {
    /* position: absolute; */
    /* left: calc(50% - 13.799999999999999rem); */
    filter: drop-shadow(0 0 0.8999999999999999rem rgb(0 0 0 / 0.4));
}

.logoText {
    position: relative;
    font-size: 4.2rem;
    width: 13.799999999999999rem;
    line-height: 3.5999999999999996rem;
    color: white;
    font-family: Helvetica, sans-serif;
    text-align: left;
}

.bgImage {
    /* object-fit: contain; */
    /* object-position: top; */
    position: relative;
    /* right:-50%; */
    /* width: 2560px; */
    z-index: -1;
}

.electionLink:hover {
    text-decoration: underline;
    color: white;
}

.electionName {
    font-weight: 600;
    color: white;
    font-family: Helvetica, sans-serif;
    font-size: 1.65rem;
    margin-top: 0;
    margin-bottom: 4.2rem;
}

.candidatesSpacer {
    display: none;
}

.candidatesContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 3.0rem;
    max-width: 96.0rem;
    margin-left: auto;
    margin-right: auto;
    /* padding-left: 4.2rem; */
    /* padding-right: 4.2rem; */
    /* margin-left: 4.2rem; */
    /* margin-right: 4.2rem; */
    /* margin: auto; */
}

.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(180, 180, 182);
    font-size: 1.14rem;
    font-weight: 600;
    flex-grow: 1;
    z-index: 1;
    /* height: 100%; */
}

.follow {
    color: white;
    background-color: dimgray;
    padding: 4px 16px;
    font-size: 20px;
    border-radius: 8px;
    position: absolute;
    bottom: 48px;
    z-index: 9;
    box-shadow: 2px 2px 0 2px rgb(0 0 0 / 40%)
}

.follow:hover {
    background-color: gray;
}

/* Move logo and planet above cards on smaller screens */
@media (max-width: 1280px) {
    .root {
        justify-content: flex-start;
    }

    .candidatesSpacer {
        display: block;
        max-width: 36.0rem;
        min-width: 30.0rem;
        width: 50%;
        height: 24.0rem;
        /* margin-top:24.0rem; */
    }

    .spacer {
        margin-left: 0.0rem;
        will-change: filter;
        /* position: absolute; */
        max-width: 36.0rem;
        min-width: 30.0rem;
        width: 50%;
        height: 24.0rem;
        flex-direction: column-reverse;
    }

    .globeLink {
        position: absolute;
    }

    .background {
        will-change: filter;
    }

    .earthAndMoon {
        justify-content: space-around;
    }

    .earth {
        height: 100%;
        width: auto;
        margin-top: 2.4rem;
    }

    .logo {
        height: 25%;
    }
}